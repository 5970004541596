import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { PageHeading, PageParagraph, PageSubHeading, SimplePage } from "../components/SimplePage"
import SimplePageHeader from "../components/SimplePageHeader"

const CookiePolicy = () => (
  <Layout>
    <SEO title="Cookie Policy" />
    <SimplePage>
      <SimplePageHeader title="Cookie Policy" />

      <PageParagraph>
        We believe you should always know what data we collect from you and how we use it.
      </PageParagraph>
      <PageParagraph>
        That's why we made it super easy to reach out to us. We have an open ear for any questions,
        concerns or suggestions.
      </PageParagraph>
      <a href="mailto:datasecurity@lumis.ai" className="font-weight-bold">
        Contact Data Security Department &raquo;
      </a>

      <PageHeading>Background</PageHeading>
      <PageParagraph>
        We use cookies to help improve your experience of www.lumis.ai. This cookie policy is part
        of our privacy policy, and covers the use of cookies between your device and our site. We
        also provide basic information on third-party services we may use, who may also use cookies
        as part of their service, though they are not covered by our policy.
      </PageParagraph>
      <PageParagraph>
        If you don’t wish to accept cookies from us, you should instruct your browser to refuse
        cookies from www.lumis.ai, with the understanding that we may be unable to provide you with
        some of your desired content and services.
      </PageParagraph>

      <PageHeading>What is a cookie?</PageHeading>
      <PageParagraph>
        A cookie is a small piece of data that a website stores on your device when you visit,
        typically containing information about the website itself, a unique identifier that allows
        the site to recognise your web browser when you return, additional data that serves the
        purpose of the cookie, and the lifespan of the cookie itself.
      </PageParagraph>
      <PageParagraph>
        Cookies are used to enable certain features (eg. logging in), to track site usage (eg.
        analytics), to store your user settings (eg. timezone, notification preferences), and to
        personalise your content (eg. advertising, language).
      </PageParagraph>
      <PageParagraph>
        Cookies set by the website you are visiting are normally referred to as “first-party
        cookies”, and typically only track your activity on that particular site. Cookies set by
        other sites and companies (ie. third parties) are called “third-party cookies”, and can be
        used to track you on other websites that use the same third-party service.
      </PageParagraph>

      <PageHeading>Types of cookies and how we use them</PageHeading>
      <PageSubHeading>Essential cookies</PageSubHeading>
      <PageParagraph>
        Essential cookies are crucial to your experience of a website, enabling core features like
        user logins, account management, shopping carts and payment processing. We use essential
        cookies to enable certain functions on our website.
      </PageParagraph>

      <PageSubHeading>Performance cookies</PageSubHeading>
      <PageParagraph>
        Performance cookies are used in the tracking of how you use a website during your visit,
        without collecting personal information about you. Typically, this information is anonymous
        and aggregated with information tracked across all site users, to help companies understand
        visitor usage patterns, identify and diagnose problems or errors their users may encounter,
        and make better strategic decisions in improving their audience’s overall website
        experience. These cookies may be set by the website you’re visiting (first-party) or by
        third-party services. We use performance cookies on our site.
      </PageParagraph>

      <PageSubHeading>Functionality cookies</PageSubHeading>
      <PageParagraph>
        Functionality cookies are used in collecting information about your device and any settings
        you may configure on the website you’re visiting (like language and timezone settings). With
        this information, websites can provide you with customised, enhanced or optimised content
        and services. These cookies may be set by the website you’re visiting (first-party) or by
        third-party service. We use functionality cookies for selected features on our site.
      </PageParagraph>

      <PageSubHeading>Targeting/advertising cookies</PageSubHeading>
      <PageParagraph>
        Targeting/advertising cookies are used in determining what promotional content is more
        relevant and appropriate to you and your interests. Websites may use them to deliver
        targeted advertising or to limit the number of times you see an advertisement. This helps
        companies improve the effectiveness of their campaigns and the quality of content presented
        to you. These cookies may be set by the website you’re visiting (first-party) or by
        third-party services. Targeting/advertising cookies set by third-parties may be used to
        track you on other websites that use the same third-party service. We use
        targeting/advertising cookies on our site.
      </PageParagraph>

      <PageHeading>Third-party cookies on our site</PageHeading>
      <PageParagraph>
        We may employ third-party companies and individuals on our websites—for example, analytics
        providers and content partners. We grant these third parties access to selected information
        to perform specific tasks on our behalf. They may also set third-party cookies in order to
        deliver the services they are providing. Third-party cookies can be used to track you on
        other websites that use the same third-party service. As we have no control over third-party
        cookies, they are not covered by our cookie policy.
      </PageParagraph>
      <PageParagraph>
        Our third-party privacy promise We review the privacy policies of all our third-party
        providers before enlisting their services to ensure their practices align with ours. We will
        never knowingly include third-party services that compromise or violate the privacy of our
        users.
      </PageParagraph>

      <PageHeading>How you can control or opt out of cookies</PageHeading>
      <PageParagraph>
        If you do not wish to accept cookies from us, you can instruct your browser to refuse
        cookies from our website. Most browsers are configured to accept cookies by default, but you
        can update these settings to either refuse cookies altogether, or to notify you when a
        website is trying to set or update a cookie.
      </PageParagraph>
      <PageParagraph>
        If you browse websites from multiple devices, you may need to update your settings on each
        individual device.
      </PageParagraph>
      <PageParagraph>
        Although some cookies can be blocked with little impact on your experience of a website,
        blocking all cookies may mean you are unable to access certain features and content across
        the sites you visit.
      </PageParagraph>
      <PageParagraph>
        More detailed information about the cookies used can be found in our Privacy Policy under
        Section 13.
      </PageParagraph>
    </SimplePage>
  </Layout>
)

export default CookiePolicy
